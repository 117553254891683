"use strict";

window.cpexWrapperVersion = 'adform 1.2, returned userIds';
var pbjs = pbjs || {};
pbjs.que = pbjs.que || [];
var adformtag = adformtag || [];

if (window.top.location.href.indexOf("cpexsrc=test") > -1 && !pbjs.CPEX_testSourceCalled) {
    pbjs.CPEX_testSourceCalled = true;
    (function () {
        var ppas = document.createElement('script');
        ppas.async = true;
        ppas.type = 'text/javascript';
        ppas.src = 'https://test.cpex.cz/hb/cpex-hb-adform.js';
        var node = document.getElementsByTagName('script')[0];
        node.parentNode.insertBefore(ppas, node);
    })();
} else {

    try { // because IE < 10 doesn't have performance.now
        window.cpexTimer = cpexTimer || {};
        cpexTimer.que = cpexTimer.que || [];
        cpexTimer.que.push(['prebid_init', performance.now()]);
    } catch (e) { }

    pbjs.que.push(function () {
        pbjs.aliasBidder('adform', 'im-adform');
    });

    pbjs.CPEX_canRenderAds = !!pbjs.CPEX_canRenderAds || false;
    pbjs.winningBids = pbjs.winningBids || {};
    pbjs.CPEX_getParameterByName = function (name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) {
            regex = new RegExp("[#&]" + name + "(=([^&#]*)|&|#|$)");
            results = regex.exec(url);
            if (!results) {
                return null;
            }
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };
    pbjs.CPEX_excludeJsonpTags = !!pbjs.CPEX_excludeJsonpTags || (pbjs.CPEX_getParameterByName('excludeJsonp') === '1');
    pbjs.CPEX_showlogs = !!pbjs.CPEX_showlogs || (pbjs.CPEX_getParameterByName('debug') === '1');
    pbjs.logging = !!pbjs.logging || (pbjs.CPEX_getParameterByName('logging') === '1');
    // pbjs.CPEX_enableAnalytics = pbjs.CPEX_enableAnalytics || (pbjs.CPEX_getParameterByName('enableAnalytics') === '1');

    pbjs.CPEX_useKeywords = !!pbjs.CPEX_useKeywords || (pbjs.CPEX_getParameterByName('useKeywords') === '1');
    pbjs.CPEX_log = function (text) {
        if (pbjs.CPEX_showlogs) { console.log(text) }
    };
    pbjs.CPEX_logError = function (text) {
        if (pbjs.CPEX_showlogs) { console.error(text) }
    };

    pbjs.CPEX_timeout = pbjs.CPEX_timeout || 1000;
    pbjs.CPEX_usdCzk = pbjs.CPEX_usdCzk || 25;
    pbjs.CPEX_eurCzk = pbjs.CPEX_eurCzk || 27;

    pbjs.CPEX_log("h000 cpex script init");
    pbjs.CPEX_Time0 = new Date().getTime();
    pbjs.CPEX_offsetNow = function () {
        return new Date().getTime() - pbjs.CPEX_Time0;
    };

    pbjs.cpexHbPbCoef = pbjs.cpexHbPbCoef || 1.39;
    pbjs.cpexHbMin = pbjs.cpexHbMin || 7;
    pbjs.bidderSettingsHbPb = pbjs.bidderSettingsHbPb ||
        function (bidResponse) {
            try {
                var hb_pb;
                var currency = (typeof bidResponse.currency !== 'undefined' && bidResponse.currency === 'EUR')
                    ? pbjs.CPEX_eurCzk : pbjs.CPEX_usdCzk;

                var logArg = bidResponse.cpm * currency - pbjs.cpexHbMin;
                if (logArg < 1) {
                    hb_pb = 1;
                } else {
                    hb_pb = Math.floor(Math.log(logArg) / Math.log(pbjs.cpexHbPbCoef));
                }
            } catch (e) {
                hb_pb = 1;
            }
            return (hb_pb < 1) ? 1 : (hb_pb < 16 ? hb_pb : 16);
        };

    try {
        pbjs.bidderSettings = pbjs.bidderSettings || {};
        pbjs.bidderSettings.standard = pbjs.bidderSettings.standard ||
        {
            alwaysUseBid: false,
            adserverTargeting: [
                {
                    key: "hb_pb", // custom buckets – we use logarithmic function
                    val: pbjs.bidderSettingsHbPb
                }]
        };
    } catch (e) {
        pbjs.CPEX_log(e);
    }

    pbjs.initAdserver = function () {
        pbjs.CPEX_log('pbjs.initAdserver');
        if (pbjs.initAdserverSet) return;
        pbjs.CPEX_buffer_renderAd = pbjs.renderAd;
        pbjs.renderAd = function (doc, adId) {
            pbjs.CPEX_log('pbjs.renderAd');
            var isCAF = false;
            try {
                for (var bidkey in pbjs.winningBids) {
                    if (!pbjs.winningBids.hasOwnProperty(bidkey)) {
                        continue;
                    }
                    pbjs.CPEX_log('caf test for key: ' + bidkey);
                    var bid = pbjs.winningBids[bidkey];
                    if (bid.adId === adId
                        && typeof top.window.cpexSkinObject === 'object'
                        && top.window.cpexSkinObject.isReadyToRender
                        && typeof cpexSkinObject !== 'undefined' && typeof cpexSkinObject.prepareSkinIframe !== 'undefined'
                        && bid.width === 2000 && bid.height === 1400
                    ) {
                        // Skin preparation
                        var adElement = cpexSkinObject.prepareSkinIframe();
                        if (adElement) {
                            doc = adElement.contentWindow.document;
                        }
                    }
                }
            } catch (e) {
                pbjs.CPEX_log(e);
            }
            if (!isCAF) {
                pbjs.CPEX_buffer_renderAd(doc, adId);
            }
        };

        pbjs.CPEX_codesWithResponse = Object.keys(pbjs.getBidResponses());
        var winningBid;

        try {
            if (pbjs.CPEX_codesWithResponse.length > 0) {
                for (var i = 0; i < pbjs.CPEX_codesWithResponse.length; i++) {
                    try {
                        winningBid = pbjs.getHighestCpmBids(pbjs.CPEX_codesWithResponse[i])[0] || undefined;
                        if (typeof winningBid !== 'undefined' && winningBid.cpm === 0) {
                            continue;
                        }
                        pbjs.winningBids[pbjs.CPEX_codesWithResponse[i]] = winningBid;
                        if (typeof winningBid !== 'undefined'
                            && winningBid.cpm
                            && (
                                winningBid.height === 1000 && winningBid.width === 1000
                                || winningBid.height === 1400 && winningBid.width === 2000
                            )
                        ) {
                            pbjs.CPEX_SkinCPM = winningBid.cpm;
                        }
                    } catch (e) {
                        pbjs.CPEX_log(e);
                    }
                }
            } else {
                pbjs.CPEX_log("h003 " + pbjs.CPEX_offsetNow() + " Timeout fired, no bids returned.");
            }
        } catch (e) {
            pbjs.CPEX_log(e);
        }

        (function () {
            var ppas = document.createElement('script');
            ppas.async = true;
            ppas.type = 'text/javascript';
            ppas.dataset.source = "cpxcp";
            var useSSL = 'https:' === document.location.protocol;
            ppas.src = (useSSL ? 'https:' : 'http:') + '//s1.adform.net/banners/scripts/adx.js';
            var node = document.getElementsByTagName('script')[0];
            node.parentNode.insertBefore(ppas, node);
        })();

        pbjs.initAdserverSet = true;

        adformtag.push(function () {
            pbjs.CPEX_log("h004 " + pbjs.CPEX_offsetNow() + ' adformtag.push');
            pbjs.que.push(function () {
                pbjs.CPEX_log("h005 " + pbjs.CPEX_offsetNow() + ' adformtag.push -> pbjs.que.push');
                for (var k = 0; k < pbjs.adUnits.length; k++) {
                    try {
                        var bid = pbjs.CPEX_ppas_getHighestBidForAdUnit(pbjs.adUnits[k].code);
                        var keyword = '';
                        if (pbjs.CPEX_useKeywords) {
                            keyword = ' and hbkey: ' + 'hb-' + pbjs.adUnits[k].code;
                            if (bid.hb_pb) {
                                keyword += ' and hbtier: ' + 'hb-' + pbjs.adUnits[k].code + '-' + bid.hb_pb;
                            }
                        }
                        pbjs.CPEX_log('h005 Code: ' + pbjs.adUnits[k].code +
                            ' with bidder: ' + bid.hb_bidder +
                            ' and price: ' + bid.hb_price +
                            ' and adId: ' + bid.hb_adId + keyword);
                        pbjs.CPEX_ppas_setPPASTargetingForAdUnit(pbjs.adUnits[k].code, bid);
                    } catch (e) {
                        pbjs.CPEX_logError(e);
                    }
                }
            });
        });
    };

    // pbjs.que.push(function () {
    //     pbjs.enableAnalytics([{ provider: 'cpex' }]);
    // })
    
    pbjs.CPEX_initDone = false;
    pbjs.CPEX_init = function () {
        if (pbjs.CPEX_initDone) {
            return;
        }
        pbjs.CPEX_initDone = true;
        pbjs.que.push(function () {
            try {
                window.cpexTimer = cpexTimer || {};
                cpexTimer.que = cpexTimer.que || [];
                cpexTimer.que.push(['pbjs_que_processing', performance.now()]);
            } catch (e) { }
            try {
                pbjs.setConfig({
                    cache: { url: 'https://prebid.adnxs.com/pbc/v1/cache' },
                    userSync: {
                        userIds: [
                            {
                                name: "id5Id",
                                params: { partner: 250 },
                                storage: { name: 'id5id', type: 'html5', expires: 365, refreshInSeconds: 8 * 3600 } // 8 hours
                            },
                            {
                                name: "sharedId",
                                storage: { name: "_sharedid", type: "cookie", expires: 365, refreshInSeconds: 8 * 3600 } // 8 hours
                            },
                            {
                                name: "criteo"
                            }
                        ]
                    }
                });
            } catch (e) { }

            pbjs.setConfig({
                consentManagement: {
                    gdpr: {
                        cmpApi: 'iab',
                        timeout: 4000, // large timeout to account for first time visitors. probably gonna delay the auction if there is no cookie
                        defaultGdprScope: false // if there is no consent string, set gdprApplies to false
                    }
                }
            });

            if (window.cpexSupplyChainId) {
                pbjs.setConfig({
                    schain: {
                        "validation": "strict",
                        "config": {
                            "ver": "1.0",
                            "complete": 1,
                            "nodes": [{ "asi": "cpex.cz", "sid": window.cpexSupplyChainId, "hp": 1 }]
                        }
                    }
                })
            }

            if (pbjs.CPEX_adUnits) {
                pbjs.CPEX_adUnitCodeBySiteZone = {};
                try {
                    var bid;
                    var rp_inventory = {};
                    if (typeof rubiconTarget !== 'undefined') {
                        rp_inventory = rubiconTarget;
                    }
                    if (rp_inventory) {
                        rp_inventory.domain = [top.location.hostname];
                        rp_inventory.api = ['fastlane'];
                        var userIDs = pbjs.getUserIds();
                        rp_inventory.crid = [userIDs.criteoId ? 1 : 0];
                        if (userIDs.id5id) { rp_inventory.id5 = [userIDs.id5id.uid && userIDs.id5id.uid !== '0' ? 1 : 0] }
                        rp_inventory.pcid = [userIDs.pubcid ? 1 : 0];
                        rp_inventory.shid = [userIDs.sharedid ? 1 : 0];
                        try {
                            rp_inventory.s2s = (function () {
                                var config = pbjs.getConfig();
                                if (config
                                    && typeof config.s2sConfig !== 'undefined'
                                    && typeof config.s2sConfig.bidders !== 'undefined'
                                    && Array.isArray(config.s2sConfig.bidders)
                                ) {
                                    return (config.s2sConfig.bidders.indexOf('rubicon') > -1) ? ['1'] : ['1-'];
                                }
                                return ['1-'];
                            })();
                        } catch (e) {
                            pbjs.CPEX_logError(e);
                        }
                    }
                    for (var i = 0; i < pbjs.CPEX_adUnits.length; i++) {
                        if (typeof pbjs.CPEX_adUnits[i].bids !== 'undefined' && pbjs.CPEX_adUnits[i].bids.length > 0) {
                            for (var j = 0; j < pbjs.CPEX_adUnits[i].bids.length; j++) {
                                bid = pbjs.CPEX_adUnits[i].bids[j];
                                if (bid) {
                                    try {
                                        if (bid.bidder === 'rubicon') {
                                            pbjs.CPEX_adUnitCodeBySiteZone[bid.params.siteId + '-' + bid.params.zoneId] = pbjs.CPEX_adUnits[i].code;
                                            if (Object.keys(rp_inventory).length > 0) {
                                                if (typeof bid.params.inventory === 'undefined') {
                                                    bid.params.inventory = {};
                                                }
                                                Object.keys(rp_inventory).forEach(function (key) {
                                                    bid.params.inventory[key] = rp_inventory[key];
                                                });
                                            }
                                        }
                                    } catch (e) {
                                        pbjs.CPEX_log('Inventory-2 error');
                                        pbjs.CPEX_logError(e);
                                    }
                                }
                            }
                        }
                    }
                } catch (e) {
                    pbjs.CPEX_log('Inventory error');
                    pbjs.CPEX_logError(e);
                }
                pbjs.addAdUnits(pbjs.CPEX_adUnits);
                pbjs.CPEX_log("h001 " + pbjs.CPEX_offsetNow() + " Ad units added.");
                pbjs.requestBids({
                    timeout: pbjs.CPEX_timeout,
                    bidsBackHandler: function () {
                        try {
                            cpexTimer.que.push(['prebid_bids_back', performance.now()]);
                        } catch (e) { }
                        pbjs.CPEX_log("h002 " + pbjs.CPEX_offsetNow()
                            + " All the bid responses are back or the timeout hits ");
                        pbjs.CPEX_log("h003 " + pbjs.CPEX_offsetNow() + " Calling initAdserver from bidsBacksHandler.");
                        pbjs.initAdserver();
                    }
                });
            } else {
                pbjs.CPEX_logError('There are no adUnits');
            }
        });
    };
    (function () {
        if (typeof cpexLoader !== 'undefined'
            && typeof cpexLoader.useSequential !== 'undefined'
            && !cpexLoader.useSequential
        ) {
            var cpexInterval;
            var callback = function () {
                setTimeout(function () {
                    pbjs.CPEX_init();
                    cpexLoader.emitEvent('pbjs.init');
                    pbjs.CPEX_log('--------------------- pbjs.init init done ------------');
                    clearInterval(cpexInterval);
                }, 0);
            };
            if (typeof pbjs.CPEX_adUnits !== 'undefined' && pbjs.CPEX_adUnits.length > 0) {
                pbjs.CPEX_log('- 1');
                callback();
            } else {
                pbjs.CPEX_log('- 2');
                pbjs.CPEX_log(pbjs.CPEX_adUnits);
                var timeout = 50;
                var time = 0;
                cpexInterval = setInterval(function () {
                    if (typeof pbjs.CPEX_adUnits !== 'undefined' && pbjs.CPEX_adUnits.length > 0) {
                        pbjs.CPEX_log('pbjs.CPEX_adUnits loaded');
                        setTimeout(function () {
                            cpexLoader.emitEvent('pbjs.setAdUnits');
                        }, 0);
                    }
                    time += timeout;
                    if (time > 10000) {
                        pbjs.CPEX_log('time tooooo long');
                        clearInterval(cpexInterval);
                    }
                }, timeout);
            }
            pbjs.CPEX_log('--------------------- pbjs.init init start ------------');
            cpexLoader.waitFor(
                'pbjs.setAdUnits',
                callback
            );
        } else {
            pbjs.CPEX_log('--------------------- pbjs.init useSequential ------------');
            pbjs.CPEX_init();
        }
    })();

    var sasCpexInterscroller = function (div, identifier, creative) {
        if (creative.indexOf('data-rp-size-id') === -1) {
            return;
        }
        var s = document.createElement("script");
        s.src = window.top.location.href.indexOf("cpexsrc=test") > -1 ? "https://test.cpex.cz/caf/v1/cpex-caf.js" : "https://cdn.cpex.cz/caf/v1/cpex-caf.js";
        s.charset = "utf-8";
        s.onload = function () {
            var targetEl = document.getElementById(div);
            if (typeof cpexCustomAdFormats !== "undefined") {
                try {
                    pbjs.CPEX_log("sasCpexInterscroller: render");
                    pbjs.CPEX_log({
                        targetEl: targetEl,
                        data: {
                            creativeCode: creative,
                            creativeType: 'interscroller-catch'
                        }
                    });
                    cpexCustomAdFormats.render({
                        targetEl: targetEl,
                        data: {
                            creativeCode: creative,
                            creativeType: 'interscroller-catch'
                        }
                    });
                    pbjs.CPEX_log("sasCpexInterscroller: render - end");
                } catch (e) {
                    pbjs.CPEX_logError(e);
                }
            } else {
                pbjs.CPEX_logError("Missing cpexCustomAdFormats in pbjs/SAS");
            }
        };
        s.onerror = function () {
            pbjs.CPEX_logError("Missing cpexCustomAdFormats in pbjs/SAS");
        };
        document.getElementsByTagName("head")[0].appendChild(s);
    };
    pbjs.CPEX_renderAd = function (winningBid) {
        pbjs.CPEX_log("Adding ad for " + winningBid);
        try {
            var getAdElement = function () {
                return document.getElementById("RTB-" + winningBid);
            };
            var adElement = getAdElement();
            adElement.onload = function () {
                var that = this;
                try {
                    /* Reason for this is HTML5 creative - native */
                    var source;
                    that.contentWindow.addEventListener("message", function (ev) {
                        pbjs.CPEX_log('CPEX_renderAd - RTB-' + winningBid + '  iframe message');

                        if (typeof ev.data !== 'undefined' && typeof ev.data.response !== 'undefined' && ev.data.response === 'cpexHTML5') {
                            source.postMessage(ev.data, '*');
                        } else {
                            try {
                                source = ev.source;
                                var data = JSON.parse(JSON.stringify(ev.data));
                                data.source = "#RTB-" + winningBid;
                                data.targetEl = "#RTB-" + winningBid;
                                that.contentWindow.parent.postMessage(data, '*');
                            } catch (e) {
                                pbjs.CPEX_log(ev);
                                pbjs.CPEX_log(e);
                            }
                        }
                    }, false);
                } catch (e) {
                    pbjs.CPEX_log(e);
                }
            }
            if (typeof pbjs.winningBids[winningBid].mediaType !== 'undefined'
                && pbjs.winningBids[winningBid].mediaType === 'native'
                && typeof pbjs.winningBids[winningBid].native !== 'undefined'
            ) {
                // Native doesn't have dimension
            } else {
                var width = +pbjs.winningBids[winningBid]["width"];
                var height = +pbjs.winningBids[winningBid]["height"];
            }

            var render = function (adElement) {
                if (adElement && typeof pbjs.winningBids[winningBid] !== 'undefined') {
                    adElement.style.width = width + "px";
                    adElement.style.height = height + "px";
                    pbjs.renderAd(adElement.contentWindow.document, pbjs.winningBids[winningBid]["adId"]);
                } else {
                    //TODO - dodat logovani
                    pbjs.CPEX_log('CPEX_renderAd - missing Ad element');
                }
            };

            setTimeout(function () {
                if (typeof pbjs.winningBids[winningBid].renderer !== 'undefined') {
                    // hlavne pro R2B2 skin ci video
                } else if ((width === 2000 && height === 1400)) {
                    // Skin priprava
                    if (typeof cpexSkinObject !== 'undefined' && typeof cpexSkinObject.prepareSkinIframe !== 'undefined') {
                        adElement = cpexSkinObject.prepareSkinIframe();
                    }
                    if (!adElement) {
                        adElement = getAdElement();
                    }
                } else if (width === 480 && height === 820) {
                    try {
                        var ramInterScroller = document.createElement('div');
                        var id = 'cpex-interscroller-hb';
                        ramInterScroller.id = id;
                        adElement.parentNode.insertBefore(ramInterScroller, adElement.nextSibling);
                        sasCpexInterscroller(id, null, pbjs.winningBids[winningBid]["ad"]);
                        return;
                    } catch (e) {
                        pbjs.CPEX_log(e);
                    }
                } else if (winningBid === 'SLIDEUP' ||
                    (
                        typeof cpexCAFSettings !== 'undefined'
                        && typeof cpexCAFSettings.slideup === 'object'
                        && typeof cpexCAFSettings.slideup.pagePosition !== 'undefined'
                        && cpexCAFSettings.slideup.pagePosition.indexOf(winningBid) > -1
                    )
                    ||
                    (
                        width === 320 && height === 150
                        || width === 300 && height === 120
                        || width === 320 && height === 100
                        || width === 320 && height === 50
                    )
                ) {
                    window.CPExSlideUp = window.CPExSlideUp || {};
                    CPExSlideUp.que = CPExSlideUp.que || [];
                    CPExSlideUp.que.push(function () {
                        try {
                            if (typeof cpexCAFSettings !== 'undefined' && typeof cpexCAFSettings['slideup'] === 'object') {
                                CPExSlideUp.setSettings(cpexCAFSettings['slideup']);
                            }
                            var slideUpElements = CPExSlideUp.getSlideUp();
                            var adElement = slideUpElements.adWrapper;
                            window.CPExSlideUp.showSlideUp(pbjs.winningBids[winningBid]["height"]);
                            render(adElement);
                        } catch (e) {
                            pbjs.CPEX_log(e);
                        }
                    });
                    var s = document.createElement('script');
                    s.src = 'https://cdn.cpex.cz/slideup/cpex-slideup.js';
                    document.head.appendChild(s);
                    return false;
                }
                render(adElement);
            }, 0);
        } catch (e) {
            pbjs.CPEX_log(e);
        }
    };

    // function to get highestBid available for defined adUnit
    pbjs.CPEX_ppas_getHighestBidForAdUnit = function (adUnit) {
        var highestBid = { hb_bidder: 'nobids', hb_price: 0, hb_adId: '', hb_size: '' };
        var winningBid = pbjs.getHighestCpmBids(adUnit)[0] || undefined;
        if (winningBid) {
            highestBid.hb_price = winningBid.cpm;
            highestBid.hb_bidder = winningBid.bidder;
            highestBid.hb_adId = winningBid.adId;
            highestBid.hb_pb = winningBid.adserverTargeting.hb_pb;
            highestBid.hb_size = '' + winningBid.width + 'x' + winningBid.height + '';
        }
        return highestBid;
    };

    // function to set Adform PPAS targeting to pass highestBid
    pbjs.CPEX_ppas_setPPASTargetingForAdUnit = function (adUnit, highestBid) {
        var mid;
        if (typeof pbjs.CPEX_MapPositions !== 'undefined' && pbjs.CPEX_MapPositions[adUnit]) {
            mid = pbjs.CPEX_MapPositions[adUnit];
        } else {
            mid = adUnit.split('-');
            mid = mid[mid.length - 1];
        }
        var d = function (highestBid) {
            if (pbjs.CPEX_SkinCPM && pbjs.CPEx_skinExcludePositions
                && typeof pbjs.CPEx_skinExcludePositions[mid] !== 'undefined'
                && pbjs.CPEx_skinExcludePositions[mid] <= pbjs.CPEX_SkinCPM
            ) {
                pbjs.CPEX_log('adUnit ' + adUnit + ' excluded because of skin CPM');
                try {
                    var remove = document.querySelectorAll('[data-adfscript="adx.adform.net/adx/?mid=' + mid + '"]');
                    if (!remove || remove.length === 0) {
                        remove = document.querySelectorAll('[data-adfscript="adx.adform.net/adx/?mid=' + mid + '&rnd=<random_number>"]');
                    }
                    if (remove && remove.length > 0) {
                        for (var i = 0; i < remove.length; ++i) {
                            remove[i].parentNode.removeChild(remove[i]);
                        }
                    }
                } catch (e) {
                    pbjs.CPEX_logError('Exclude adunit error: ' + e);
                }
            } else {
                if (highestBid.hb_bidder === 'nobids' || highestBid.hb_adId === '') {
                    pbjs.CPEX_log('h006 no bids for adUnit ' + adUnit);
                } else {
                    adformtag.setTargeting(mid, 'hb_bidder', highestBid.hb_bidder);
                    adformtag.setCustomData(mid, 'hb_adid', highestBid.hb_adId);
                    pbjs.CPEX_log('h006a: adformtag.setTargeting(' + mid + ', "hb_bidder", ' + highestBid.hb_bidder + ')');
                    pbjs.CPEX_log('h006b: adformtag.setCustomData(' + mid + ', "hb_adid", ' + highestBid.hb_adId + ')');

                    if (pbjs.CPEX_useKeywords) {
                        adformtag.setTargeting(mid, 'hbkey', 'hb-' + adUnit);
                        if (highestBid.hb_pb) {
                            adformtag.setTargeting(mid, 'hbtier', 'hb-' + adUnit + '-' + highestBid.hb_pb);
                        }
                    }
                    pbjs.CPEX_log('h006c: adformtag.setTargeting(' + mid + ', "hbtier", hb-' + adUnit + '-' + highestBid.hb_pb + ')');
                    adformtag.setPrice(mid, highestBid.hb_price);
                    pbjs.CPEX_log('h006d: adformtag.setPrice(' + mid + ', ' + highestBid.hb_price + ')');
                }
            }
        };
        window.AdTrack && window.AdTrack.hb ? AdTrack.hb.getHighestBidForAdUnit(mid, function (ad) {
            if (ad && 0 < ad.length && (ad = ad[0], ad.adID && ad.price && ad.price >= highestBid.hb_price)) {
                ad.isBranding &&
                    (!pbjs.CPEX_SkinCPM || ad.price >= pbjs.CPEX_SkinCPM) && (pbjs.CPEX_SkinCPM = ad.price);
                highestBid = {
                    hb_adId: ad.adID,
                    hb_price: ad.price
                };
                ad = ad.targeting;
                for (var b in ad.customTargeting) ad.customTargeting.hasOwnProperty(b) && (highestBid[b] = ad.customTargeting[b])
            }
            d(highestBid)
        }) : d(highestBid)
    };

    pbjs.CPEX_excludeTagWhenHBResponds = function (site, zone) {
        try {
            if (pbjs.CPEX_excludeJsonpTags) {
                var sz = site + '-' + zone;
                return (
                    typeof pbjs.CPEX_adUnitCodeBySiteZone[sz] !== 'undefined'
                    && pbjs.CPEX_codesWithResponse.indexOf(pbjs.CPEX_adUnitCodeBySiteZone[sz]) > -1
                );
            } else {
                return false;
            }
        } catch (e) {
            pbjs.CPEX_logError(e);
        }
        return false;
    };

    try {
        if (typeof window.cpex_AAM_test === 'undefined') {
            window.cpex_AAM_test = true;
            var callAAM = true;
            var scripts = document.getElementsByTagName('script');
            for (var i = 0, len = scripts.length; i < len; i++) {
                if (scripts[i].src.indexOf('satelliteLib') !== -1 || scripts[i].src.indexOf('cpex-aam') !== -1) {
                    callAAM = false;
                    break;
                }
            }
            if (callAAM) {
                window.cpex_AAM_conf = conf;
                var s = top.document.createElement('script');
                var aamUrl = pbjs.CPEX_getParameterByName('aam-url');
                s.src = ((aamUrl) ? aamUrl : '//cdn.cpex.cz/aam/cpex-aam-min.js');
                s.async = true;
                document.head.appendChild(s);
            }
        }
    } catch (e) { }

    if (
        // if missing performance API don't load timer
        !(typeof performance === 'undefined' || typeof performance.now === 'undefined')
        &&
        (typeof window.top.cpexTimer === 'undefined' || typeof window.top.cpexTimer.version === 'undefined')
        &&
        (typeof window.cpexLoader === 'undefined')
    ) {
        (function () {
            var s = document.createElement('script');
            s.async = true;
            s.type = 'text/javascript';
            s.src = 'https://cdn.cpex.cz/timer/v1/cpex-timer.js';
            document.head.appendChild(s);
        })();
    }
}
